import React from 'react';
import ReactDOM from 'react-dom';
import { RedocStandalone } from 'redoc';

// https://github.com/Rebilly/ReDoc/blob/master/src/theme.ts
const theme = {
  colors: {
    primary: {
      main: '#262260'
    },
    success: {
      main: '#4DC38D'
    },
    warning: {
      main: '#FAAE39'
    },
    error: {
      main: '#DC2363'
    }
  }
};

ReactDOM.render(
  <RedocStandalone specUrl='/openapi.yaml' options={{
    nativeScrollbars: true,
    theme: theme,
  }}/>,
  document.body);
